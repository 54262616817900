import { getAge, getDateBirthFromTaxId, isUserInGroup } from "../api/utils";
import {
  getCheckinDetailUrl,
  getCreditDetailUrl,
  getCreditInsertUrl,
  getCustomerNewCheckInUrl,
  getCustomerStripeUrl,
  getCustomerSubscriptionListUrl,
  getDocumentUrl,
  getSubscriptionDetailUrl,
} from "../api/urls";

import { AgreementLink } from "./AgreementLink";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ListGroup from "react-bootstrap/ListGroup";
import NotesDisplay from "./NotesDisplay";
import React from "react";
import Row from "react-bootstrap/Row";
import StateIcon from "../components/StateIcon";
import addBsArrowRight from "../components/AddBsArrowRight";
import constants from "../constants";
import dropDownHeaderView from "../components/DropDownHeaderView";
import styled from "@emotion/styled/macro";

// Styles
const EmojiSpan = styled.span`
  font-size: 1.2rem;
`;

function getLinkCustomerStripe(customerId) {
  if (!customerId) return <></>;
  const item = (
    <Dropdown.Item href={getCustomerStripeUrl(customerId)} target="_blank">
      {addBsArrowRight("Utente Stripe")}
    </Dropdown.Item>
  );
  return item;
}

function CustomerDetailForm({
  data,
  checkinList,
  counterCheckIn,
  subscriptionsList,
  creditList,
  openCustomerUpdate,
  openCustomerCheckinsList,
  userSetting,
  agreementPrivacy,
  agreementLaser,
  agreementLaserMinor,
}) {
  const customerStripeId = data.stripeId;

  const isManagementStripeMenu = isUserInGroup(
    userSetting,
    constants.GROUPS_MANAGEMENT_STRIPE_MENU
  );

  //
  // load Button for list all Check-Ins
  //
  let btnPlusCheckIn = null;
  if (checkinList?.length !== 0) {
    btnPlusCheckIn = (
      <Button
        variant="outline-secondary"
        size="sm"
        as="a"
        onClick={openCustomerCheckinsList}
        className="pr-2"
      >
        Lista Check-In ({counterCheckIn})
      </Button>
    );
  }
  //
  //load list check-ins
  //
  let checkinListGroupItems = null;
  if (checkinList?.length === 0) {
    checkinListGroupItems = (
      <ListGroup.Item disabled className="list-group-item-ck">
        Non ci sono check-in effettuati
      </ListGroup.Item>
    );
  } else {
    checkinList = checkinList.slice(0, 3);
    checkinListGroupItems = (
      <>
        {checkinList.map((s) => {
          return (
            <ListGroup.Item
              key={s.uuid}
              value={s.index}
              className="list-group-item-ck"
              action
              href={getCheckinDetailUrl(s.uuid)}
            >
              {s.checkInStatus} {s.checkInDate.substr(2)} |{" "}
              {s.checkInPlanShortName} | {s.checkInProductName}
            </ListGroup.Item>
          );
        })}
      </>
    );
  }

  //
  // load Button for list all Subscription
  //
  let btnPlusSubscription = null;
  if (subscriptionsList?.length !== 0) {
    btnPlusSubscription = (
      <Button
        variant="outline-secondary"
        size="sm"
        as="a"
        href={getCustomerSubscriptionListUrl(data?.uuid)}
        className="pr-2"
      >
        Lista Abbonamenti ({subscriptionsList?.length})
      </Button>
    );
  }

  //
  // Load list Subscription
  //

  let subscriptionsListGroupItems = null;
  if (subscriptionsList?.length === 0) {
    subscriptionsListGroupItems = (
      <ListGroup.Item disabled className="list-group-item-ck">
        Non ci sono abbonamenti
      </ListGroup.Item>
    );
  } else {
    subscriptionsList = subscriptionsList.slice(0, 3);
    subscriptionsListGroupItems = (
      <>
        {subscriptionsList.map((s) => {
          const status = (
            <StateIcon
              nameIcon={s?.stateSubscription}
              // colorIc="light"
              objType="Icon"
            ></StateIcon>
          );
          let payFor = null;
          if (!!s.customerFullName) {
            payFor = (
              <EmojiSpan title={`Paga per: ${s.customerFullName}`}>
                <span role="img" aria-label="cash">
                  | 💰
                </span>
              </EmojiSpan>
            );
          }
          if (!!s.invoicingFullName) {
            payFor = (
              <EmojiSpan title={`Pagato da: ${s.invoicingFullName}`}>
                <span role="img" aria-label="cash">
                  | 👨‍👦
                </span>
              </EmojiSpan>
            );
          }
          let unpaidState = null;
          if (s?.paidState === constants.SUBSCRIPTION_STATEPAID_UNPAID) {
            unpaidState = (
              <EmojiSpan title={`Ci sono rate non pagate`}>
                <span role="img" aria-label="cash">
                  | ⚠️
                </span>
              </EmojiSpan>
            );
          }

          let unsignedContract = null;
          if (!s?.agreementContract) {
            unsignedContract = "(Contratto non firmato)";
          }
          return (
            <ListGroup.Item
              key={s.uuid}
              value={s.index}
              className="list-group-item-ck"
              action
              href={getSubscriptionDetailUrl(s.uuid)}
            >
              {status} {s.startDate.substr(2)} | {s.planShortName} |{" "}
              {s.paymentType} {payFor} {unpaidState} {unsignedContract}
            </ListGroup.Item>
          );
        })}
      </>
    );
  }

  //
  // Load list Credit
  //
  let creditListGroupItems = null;
  if (creditList?.length === 0) {
    creditListGroupItems = (
      <ListGroup.Item disabled className="list-group-item-ck">
        Non ci sono crediti
      </ListGroup.Item>
    );
  } else {
    creditList = creditList.slice(0, 3);
    creditListGroupItems = (
      <>
        {creditList.map((s) => {
          const iconCredits = [
            constants.CREDIT_STATE_CLOSED,
            constants.CREDIT_STATE_EXPIRED,
          ].includes(s?.creditState)
            ? constants.CREDIT_STATE_CANCELED
            : s?.creditState;

          const status = (
            <StateIcon
              nameIcon={iconCredits}
              // colorIc="light"
              objType="Icon"
            ></StateIcon>
          );

          return (
            <ListGroup.Item
              key={s.uuid}
              value={s.index}
              className="list-group-item-ck"
              action
              href={getCreditDetailUrl(s.uuid)}
            >
              {status} {s.startDate.substr(2)} | {s.planShortName} |{" "}
              {s.creditType} | CheckIn {s.checkinCount} / {s.sessionQuantity}
            </ListGroup.Item>
          );
        })}
      </>
    );
  }

  // Check the age of customer
  const age = !!data?.taxId ? getAge(getDateBirthFromTaxId(data?.taxId)) : 99;
  const laserDocumentUrl =
    age > 17
      ? agreementLaser?.signingDocumentUrl
      : agreementLaserMinor?.signingDocumentUrl;

  const agreement = {
    first: data?.first,
    last: data?.last,
    taxId: data?.taxId,
    customerUuid: data?.uuid,
    email: data?.email,
    privacyDocumentUrl: agreementPrivacy?.signingDocumentUrl,
    laserDocumentUrl: laserDocumentUrl,
    privacy: data?.agreementPrivacyConsent,
    laser: data?.agreementLaserTreatment,
  };

  const privacyLink = <AgreementLink agreement={agreement}></AgreementLink>;

  const signPrivacy = <Col>{privacyLink}</Col>;
  const notes = data.notes == null ? "" : data.notes;

  const alertNeedsUpdate = (
    <Alert variant="danger">
      <p>L'anagrafica del cliente deve essere necessariamente aggiornata.</p>
    </Alert>
  );

  const alertNeedsPrivacy = (
    <Alert variant="info">
      <p>
        Il cliente non ha firmato il Consenso per il Trattamento Dati Personali
        "Privacy".
      </p>
    </Alert>
  );

  const isPrivacy = !!data?.agreementPrivacyConsent;
  const needsUpdate = !!data?.needsUpdate;

  let infoMessage = null;

  infoMessage = !isPrivacy ? alertNeedsPrivacy : null;
  if (infoMessage === null) infoMessage = needsUpdate ? alertNeedsUpdate : null;

  const btnCheckIn = (
    <Button
      variant="primary"
      as="a"
      href={getCustomerNewCheckInUrl(data?.uuid)}
    >
      Nuovo Check-In
    </Button>
  );

  const mail =
    data?.email.lastIndexOf(constants.MISSING_EMAIL_MARKER) ===
    constants.STRING_NOT_FOUND
      ? data?.email
      : "";

  const dropdownHeader = !!customerStripeId ? (
    <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
  ) : null;

  const dropdownDivider = !!customerStripeId ? <Dropdown.Divider /> : null;

  // Visualizzazione Consenso Privacy
  let labelPrivacy = "Consenso Privacy: No";
  if (isPrivacy) {
    labelPrivacy = "Consenso Privacy: Si";
    if (!!data?.agreementPrivacyConsent?.signedDocumentUrl) {
      labelPrivacy = (
        <a
          href={getDocumentUrl(
            data?.agreementPrivacyConsent?.signedDocumentUrl
          )}
          target="blank"
          rel="noopener noreferrer"
        >
          Consenso Privacy: Si
        </a>
      );
    }
  }
  // Visualizzazione Consenso Laser
  let labelLaser = "Consenso trattamento Laser: No";
  if (!!data?.agreementLaserTreatment) {
    labelLaser = "Consenso trattamento Laser: Si";
    if (data?.agreementLaserTreatment?.signedDocumentUrl) {
      labelLaser = (
        <a
          href={getDocumentUrl(
            data?.agreementLaserTreatment?.signedDocumentUrl
          )}
          target="blank"
          rel="noopener noreferrer"
        >
          Consenso trattamento Laser: Si
        </a>
      );
    }
  }

  return (
    <>
      <Row>
        <Col xs={7}>
          <h4 className="text-center">Dati Cliente</h4>
        </Col>
        <Col xs={5} className="text-end">
          <ButtonToolbar className="float-end">
            <Row>
              <Col xs={8}>{btnCheckIn}</Col>
              <Col xs={4}>
                <DropdownButton
                  title="Azioni"
                  id="dropdown-menu-align-right"
                  variant="outline-primary"
                >
                  <Dropdown.Item onClick={openCustomerUpdate}>
                    Modifica Cliente
                  </Dropdown.Item>
                  <Dropdown.Item href={getCreditInsertUrl(data?.uuid)}>
                    Inserimento Credito
                  </Dropdown.Item>
                  {dropdownDivider}
                  {dropdownHeader}
                  {isManagementStripeMenu
                    ? getLinkCustomerStripe(customerStripeId)
                    : null}
                  {signPrivacy}
                </DropdownButton>
              </Col>
            </Row>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6>Nome:</h6>
          <span>{data?.first}</span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{data?.last}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Mail</h6>
          <span>{mail}</span>
        </Col>
        <Col>
          <h6>Codice Fiscale</h6>
          <span>{data?.taxId}</span>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h6>Via:</h6>
          <span>{data?.address?.road}</span>
        </Col>
        <Col>
          <h6>Numero Civico</h6>
          <span>{data?.address?.houseNumber}</span>
        </Col>
        <Col>
          <h6>Telefono</h6>
          <span>{data?.phoneNumber}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Città</h6>
          <span>{data?.address?.city}</span>
        </Col>
        <Col>
          <h6>Provincia</h6>
          <span>{data?.address?.county}</span>
        </Col>
        <Col>
          <h6>Cap</h6>
          <span>{data?.address?.postcode}</span>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h6>Note</h6>
          <NotesDisplay notes={notes} />
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col>
              <h5>Abbonamenti</h5>
            </Col>
            <Col xs={2}></Col>
            <Col>
              <div style={{ textAlign: "right" }}>{btnPlusSubscription}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListGroup className="list-group-ck">
                {subscriptionsListGroupItems}
              </ListGroup>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6}>
          <Row>
            <Col>
              <h5>Ultimi Check-In</h5>
            </Col>
            <Col xs={3}></Col>
            <Col>
              <div style={{ textAlign: "right" }}>{btnPlusCheckIn}</div>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListGroup className="list-group-ck">
                {checkinListGroupItems}
              </ListGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col>
              <h5>Crediti</h5>
            </Col>
            <Col xs={2}></Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <ListGroup className="list-group-ck">
                {creditListGroupItems}
              </ListGroup>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6}>
          <Row>
            <Col>
              <div className="mt-4" />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <span>{labelPrivacy}</span>
            </Col>
            <Col xs={6}>
              <span>{labelLaser}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>{infoMessage}</Row>
    </>
  );
}
export default CustomerDetailForm;
