import {
  ErrorMessage,
  extractApplicationError,
  extractFormErrors,
} from "./ErrorMessage";
import { FormProvider, useForm } from "react-hook-form";
import React, { useState } from "react";
import {
  customerValidationType,
  getCustomerValidationResolver,
} from "./validators";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import { CustomerInput } from "./CustomerInput";
import Form from "react-bootstrap/Form";
import FormElement from "./FormElement";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import constants from "../constants";
import { controlTaxiId } from "../api/utils";

function ContinueModal({ onContinue, show = false }) {
  return (
    <Modal show={show} onHide={onContinue}>
      <Modal.Header closeButton>
        <Modal.Title>Aggiornamento Dati Cliente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        I dati aggiornati sono stati registrati correttamente
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onContinue}>
          Continua
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function CustomerUpdateForm({
  data,
  updateData,
  updateCustomer,
  updating,
  completed,
  updateError,
  modalContinueClicked = () => {},
  referralSources,
  validation = customerValidationType.RECEIVING,
}) {
  // Form hooks
  const mail =
    data?.email.lastIndexOf(constants.MISSING_EMAIL_MARKER) ===
    constants.STRING_NOT_FOUND
      ? data?.email
      : "";

  const useFormMethods = useForm({
    resolver: getCustomerValidationResolver(validation),

    defaultValues: {
      last: data?.last,
      first: data?.first,
      email: mail,
      phoneNumber: data?.phoneNumber,
      notes: data?.notes,
      taxId: !!data?.taxId ? data?.taxId : "",
      referralSource: data?.referralSource?.uuid,
      road: data?.address?.road,
      houseNumber: data?.address?.houseNumber,
      city: !!data?.address?.city ? data?.address?.city : null,
      county: !!data?.address?.county ? data?.address?.county : null,
      postcode: !!data?.address?.postcode ? data?.address?.postcode : null,
    },
  });
  const { register, handleSubmit, errors } = useFormMethods;

  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [msgValue, setMsgValue] = useState("");

  const possibleErrorList = [
    extractApplicationError(updateData),
    extractFormErrors(errors),
    updateError,
  ];

  const onSubmit = async (formData) => {
    setMsgValue(""); //erase previus error
    const resultTaxiId = controlTaxiId(
      formData.taxId,
      formData.first,
      formData.last
    );
    if (resultTaxiId !== "") {
      setMsgValue(<Alert variant="danger"> {resultTaxiId} </Alert>);
      return;
    }
    const customer = {
      first: formData.first,
      last: formData.last,
      taxId: !!formData.taxId ? formData.taxId : null,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      notes: formData.notes,
      referralSource: formData.referralSource,
      address: {
        road: formData.road,
        city: formData.city,
        county: formData.county,
        postcode: formData.postcode,
        houseNumber: formData.houseNumber,
      },
    };
    const result = await updateCustomer({
      variables: {
        customer,
        uuid: data.uuid,
      },
    });
    const resultErrors =
      result?.errors || extractApplicationError(result?.data);
    if (!resultErrors) {
      setUpdateSuccess(true);
      setShowModal(true);
    }
  };

  const buttonSpinner = <Spinner animation="border" variant="light" />;

  const updatingSuccess = <Alert variant="success"> Cliente aggiornato</Alert>;

  return (
    <>
      <FormProvider {...useFormMethods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={11}>
              <h4 className="text-center"> Aggiornamento Dati Cliente </h4>
            </Col>
            <Col xs={1}>
              <ButtonGroup>
                <Col>
                  <Button variant="primary" type="submit" disabled={updating}>
                    {updating ? buttonSpinner : "Aggiorna"}
                  </Button>
                </Col>
              </ButtonGroup>
            </Col>
          </Row>
          <CustomerInput fullInfo={true} />
          <Row>
            <Form.Group as={Col} xs={6} controlId="notes">
              <Form.Label>Note</Form.Label>
              <Form.Control as="textarea" rows="1" {...register("notes")} />
            </Form.Group>

            <Form.Group as={Col} xs={6} controlId="referralSource">
              <FormElement
                label="Fonte Contatto"
                name="referralSource"
                register={register}
                errors={errors}
                controlOptions={{ as: "select" }}
              >
                {referralSources.map((p) => (
                  <option key={p.uuid} value={p.uuid}>
                    {p.name}
                  </option>
                ))}
              </FormElement>
            </Form.Group>
          </Row>
          <Row>
            <ErrorMessage errors={possibleErrorList} />
          </Row>
          <Row>{updateSuccess ? updatingSuccess : null}</Row>
          <Row>{msgValue !== "" ? msgValue : null}</Row>
          <Row></Row>
        </Form>
      </FormProvider>

      <ContinueModal
        show={showModal}
        onContinue={() => {
          setShowModal(false);
          modalContinueClicked();
        }}
      />
    </>
  );
}
export default CustomerUpdateForm;
