import React, { useEffect, useState } from "react";
import {
  calculateDurationCheckIn,
  checkFeatureFlagRequirement,
  formatLocalTime,
  isProd,
} from "../api/utils";
import {
  getCheckinUpdateUrl,
  getCreateReceiptstUrl,
  getCreditDetailUrl,
  getCustomerUrl,
  getReceiptDetailUrl,
  getSubscriptionDetailUrl,
} from "../api/urls";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Modal from "react-bootstrap/Modal";
import NotesDisplay from "./NotesDisplay";
import Row from "react-bootstrap/Row";
import addBsArrowRight from "../components/AddBsArrowRight";
import constants from "../constants";
import dropDownHeaderView from "../components/DropDownHeaderView";

function ConfirmCloseCheckinModal({ onConfirm, onCancel, show = false }) {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Chiudi Check-In</Modal.Title>
      </Modal.Header>
      <Modal.Body>Questa azione chiude il check-in</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Annulla
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Chiudi Check-In
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function CustomerCheckInDetailForm({
  data,
  currentLocation,
  updateStateCheckIn,
  creating,
  completed,
  stateCheckIn,
}) {
  const checkindata = data?.checkin;
  const invoice = data?.getInvoiceForCheckin[0];
  const isRequirementForSignatureConsent = checkFeatureFlagRequirement(
    data.featureFlags,
    constants.FEATURE_FLAG_FOR_SIGNATURE_CONSENT
  );
  let typeCheckin;
  let actionTypeCheckin;
  let paidAccess = false;

  const [showModal, setShowModal] = useState(false);
  const [showStateCheckIn, setShowStateCheckIn] = useState(stateCheckIn);
  const [msgValue, setMsgValue] = useState(null);
  const [showPrivacyInfo, setShowPrivacyInfo] = useState(
    !checkindata.customer?.agreementPrivacyConsent &&
      isRequirementForSignatureConsent
  );
  const [showLaserInfo, setshowLaserInfo] = useState(false);

  const consentLaserIsRequired = checkindata?.product?.consentLaserIsRequired;

  //Calculate duration Check-In
  const checkInDuration = ` (durata: ${calculateDurationCheckIn(
    checkindata?.checkInStatus,
    checkindata?.closedDate,
    checkindata?.timestamp
  )} minuti)`;

  useEffect(() => {
    function searchConsentLaserIsRequired(customer, consentLaserIsRequired) {
      checkConsentLaserIsRequired(customer, consentLaserIsRequired, "info");
      setshowLaserInfo(true);
    }

    if (!showLaserInfo && stateCheckIn && isRequirementForSignatureConsent)
      searchConsentLaserIsRequired(
        checkindata?.customer,
        consentLaserIsRequired
      );
  }, [
    consentLaserIsRequired,
    checkindata?.customer,
    showLaserInfo,
    stateCheckIn,
    isRequirementForSignatureConsent,
  ]);

  const isSameLocation =
    currentLocation?.uuid === checkindata?.location?.uuid ? true : false;
  switch (checkindata?.checkinFundingSource?.uuid) {
    case constants.CHECKIN_FUNDING_SUBSCRIPTION:
      typeCheckin = `${checkindata?.subscription?.startDate} - ${checkindata?.subscription?.plan?.name}`;
      actionTypeCheckin = (
        <Dropdown.Item
          href={getSubscriptionDetailUrl(checkindata?.subscription?.uuid)}
        >
          {addBsArrowRight("Abbonamento")}
        </Dropdown.Item>
      );
      break;
    case constants.CHECKIN_FUNDING_CREDIT:
      typeCheckin = `${checkindata?.credit?.startDate} - ${checkindata?.checkinFundingSource?.name} - ${checkindata?.credit?.creditType?.name} - ${checkindata?.credit?.plan?.name}`;
      actionTypeCheckin = (
        <Dropdown.Item href={getCreditDetailUrl(checkindata?.credit?.uuid)}>
          {addBsArrowRight("Credito")}
        </Dropdown.Item>
      );
      break;
    default:
      typeCheckin = checkindata?.checkinFundingSource?.name;
      checkindata?.checkinFundingSource?.uuid ===
      constants.CHECKIN_FUNDING_PAID_ACCESS
        ? (paidAccess = true)
        : (paidAccess = false);
  }
  const worker = !!checkindata?.worker?.uuid
    ? `${checkindata?.worker?.last} ${checkindata?.worker?.first}`
    : constants.DEFAULT_WORKER_UUID;

  const station = !!checkindata?.station?.uuid
    ? checkindata?.station?.name
    : constants.DEFAULT_STATION_UUID;

  const present = checkindata?.isPresent ? "Si" : "No";

  let reinbursement = null;
  reinbursement = !!invoice?.sdiSentDate ? (
    <Col>
      <h6>Rimborsata? </h6>
      <span>{!!invoice?.creditNoteId ? "Si" : "No"} </span>
    </Col>
  ) : (
    <Col></Col>
  );

  const receiptAlreadyIssued = (
    <>
      <div className="mt-4" />
      <hr />
      <Row>
        <Col>
          <h6>Numero ricevuta </h6>
          <span>{invoice?.progressiveNumber} </span>
        </Col>
        <Col>
          <h6>Data ricevuta </h6>
          <span>{invoice?.date} </span>
        </Col>
        <Col>
          <h6>Data invio MEF </h6>
          <span>{invoice?.sdiSentDate} </span>
        </Col>
        {reinbursement}
      </Row>
    </>
  );
  const receiptNotIssued = (
    <>
      <div className="mt-4" />
      <hr />
      <Row>
        <Col>
          <h6>Numero ricevuta </h6>
          <span>Non è stata emessa alcuna ricevuta </span>
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </>
  );

  const receipt = invoice?.progressiveNumber
    ? receiptAlreadyIssued
    : receiptNotIssued;

  const enabledIssueReceipt =
    paidAccess &&
    isSameLocation &&
    !invoice?.progressiveNumber &&
    checkindata?.isPresent;

  const enabledReceiptDetail = paidAccess && !!invoice?.progressiveNumber;

  let disableUpdateCheckin = !isSameLocation;

  const btn_paid_access = (
    <>
      <Dropdown.Item
        href={getCreateReceiptstUrl(checkindata?.uuid)}
        disabled={!enabledIssueReceipt}
      >
        Emetti Ricevuta
      </Dropdown.Item>
    </>
  );

  const dropdownReceipt = paidAccess ? btn_paid_access : null;

  const detailPaidAccess = (
    <>
      <Dropdown.Item
        href={getReceiptDetailUrl(invoice?.uuid)}
        disabled={!enabledReceiptDetail}
      >
        {addBsArrowRight("Ricevuta")}
      </Dropdown.Item>
    </>
  );

  const receiptDetail = paidAccess ? detailPaidAccess : null;

  function checkPrivacySignIsRequired(customer) {
    if (!isProd()) return false;

    let result = false;
    if (!!customer?.agreementPrivacyConsent === false) {
      result = true;
      setMsgValue(
        <Alert variant="danger">
          La registrazione non può avvenire perchè il cliente non ha firmato il
          Consenso "Privacy".
        </Alert>
      );
    }
    return result;
  }

  const alertNeedsPrivacy =
    stateCheckIn && !checkindata?.customer?.agreementPrivacyConsent ? (
      <Alert variant="info">
        <p>Manca il Consenso "Privacy".</p>
      </Alert>
    ) : null;

  const onUpdate = async () => {
    const result = await updateStateCheckIn({
      variables: {
        // checkin,
        checkInStatus: constants.CHECK_IN_STATUS_TYPE_COMPLETED,
        guid: checkindata.uuid,
      },
    });
    if (result.data) {
      setShowStateCheckIn(false);
      setShowModal(false);
    }
  };
  function checkConsentLaserIsRequired(
    customer,
    consentLaserIsRequired,
    variant = "danger"
  ) {
    let result = false;
    let msg =
      "La registrazione non può avvenire perchè manca il Consenso al Trattamento Laser";
    if (variant === "info") msg = "Manca il Consenso al Trattamento Laser";
    if (!customer.agreementLaserTreatment && consentLaserIsRequired) {
      result = true;
      setMsgValue(<Alert variant={variant}>{msg}</Alert>);
    }
    return result;
  }

  const btnCloseCheckIn = showStateCheckIn ? (
    <>
      <Button
        variant="primary"
        onClick={() => {
          setMsgValue(null);
          setShowPrivacyInfo(false);
          if (isRequirementForSignatureConsent) {
            if (checkPrivacySignIsRequired(checkindata?.customer)) return;
            if (
              checkConsentLaserIsRequired(
                checkindata?.customer,
                consentLaserIsRequired
              )
            )
              return;
          }
          setShowModal(true);
        }}
      >
        Chiudi Check-In
      </Button>
    </>
  ) : null;

  return (
    <>
      <Row>
        <Col xs={9}>
          <h3 className="text-center">
            Check-In {showStateCheckIn ? `  (Aperto)` : null}
          </h3>
        </Col>
        <Col xs={3}>
          <ButtonGroup>
            <Col xs={8}>{btnCloseCheckIn}</Col>
            <Col xs={4}>
              <DropdownButton
                menualign="right"
                title="Azioni"
                id="dropdown-menu-align-right"
                variant="outline-secondary"
              >
                <Dropdown.Item
                  href={getCheckinUpdateUrl(checkindata?.uuid)}
                  disabled={disableUpdateCheckin}
                >
                  Modifica Check-In
                </Dropdown.Item>
                {dropdownReceipt}
                <Dropdown.Divider />
                <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                <Dropdown.Item
                  href={getCustomerUrl(checkindata?.customer?.uuid)}
                >
                  {addBsArrowRight("Cliente")}
                </Dropdown.Item>
                {receiptDetail}
                {actionTypeCheckin}
              </DropdownButton>
            </Col>
          </ButtonGroup>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Nome </h6>
          <span>{checkindata.customer.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{checkindata.customer.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{checkindata.customer.email}</span>
        </Col>
      </Row>

      <hr />
      <Row>
        <Col xs={5}>
          <h6>Tipo </h6>
          <span>{typeCheckin} </span>
        </Col>
        <Col xs={3}>
          <h6>Data</h6>
          <span>{checkindata?.date}</span>
        </Col>
        <Col>
          <h6 xs={2}>Ora</h6>
          <span>
            {formatLocalTime(checkindata?.time)} {checkInDuration}
          </span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col xs={5}>
          <h6>Trattamento</h6>
          <span>{checkindata?.product?.name}</span>
        </Col>
        <Col xs={3}>
          <h6>Estetista </h6>
          <span>{worker} </span>
        </Col>

        <Col>
          <h6 xs={2}>Numero zone</h6>
          <span>{checkindata?.productZones}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col xs={5}>
          <h6>Box</h6>
          <span>{station}</span>
        </Col>
        <Col xs={3}>
          <h6>Sede</h6>
          <span>{checkindata?.location?.name}</span>
        </Col>
        <Col xs={3}>
          <h6>Presenza</h6>
          <span>{present}</span>
        </Col>
      </Row>
      {paidAccess ? receipt : null}
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Note</h6>
          <NotesDisplay notes={checkindata?.notes} />
        </Col>
      </Row>
      <Row>{showPrivacyInfo ? alertNeedsPrivacy : null}</Row>
      <Row>{msgValue !== "" ? msgValue : null}</Row>
      <ConfirmCloseCheckinModal
        show={showModal}
        onConfirm={onUpdate}
        onCancel={() => {
          setShowModal(false);
        }}
      />
    </>
  );
}

export default CustomerCheckInDetailForm;
