import * as yup from "yup";

import React, { useState } from "react";
import { formatDateIso, isEmpty } from "../../api/utils";
import { getCheckinDetailUrl, getCustomerUrl } from "../../api/urls";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ErrorMessage } from "../../../src/components/ErrorMessage";
import Form from "react-bootstrap/Form";
import FormElement from "../../components/FormElement";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import addBsArrowRight from "../../components/AddBsArrowRight";
import dropDownHeaderView from "../../components/DropDownHeaderView";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  emailInvio: yup.string().email("Mail non valida"),
});

function ContinueModal({ onContinue, show = false }) {
  return (
    <Modal show={show} onHide={onContinue}>
      <Modal.Header closeButton>
        <Modal.Title>Emissione Ricevuta</Modal.Title>
      </Modal.Header>
      <Modal.Body>La Ricevuta è stata emessa correttamente</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onContinue}>
          Continua
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ReceiptCreateForm({
  data,
  createReceipt,
  creating,
  completed,
  createError,
  modalContinueClicked = () => {},
}) {
  const [showModalContinue, setShowModalContinue] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const checkin = data?.checkin;
  const totalPrice = checkin?.productZones * data?.productPrice;
  // Form hooks
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      sendEmail: checkin?.customer?.email,
    },
  });
  const onSubmit = async (formData) => {
    const checkinUuid = checkin?.uuid;
    const invoiceDate = formatDateIso(new Date());
    const notes = null;
    const internalNotes =
      formData.InternalNotes.trim() === "" ? null : formData.InternalNotes;
    const sendEmail =
      !!formData.sendEmail && showEmail
        ? formData.sendEmail
        : checkin?.customer?.email;
    const result = await createReceipt({
      variables: {
        invoiceCheckinUuid: checkinUuid,
        invoiceDate: invoiceDate,
        invoiceNotes: notes,
        invoiceInternalNotes: internalNotes,
        invoiceSendEmail: sendEmail,
      },
    });

    if (!result?.errors) {
      setShowModalContinue(true);
    }
  };

  function handleChangeCheck() {
    setShowEmail(!showEmail);
  }

  const buttonSpinner = <Spinner animation="border" variant="light" />;
  const creatingSuccess = (
    <Alert variant="success"> Registrazione effettuata</Alert>
  );
  let btnCreate = (
    <Form.Group as={Col} controlId="update">
      <Col>
        <Button
          variant={isEmpty(errors) ? "primary" : "danger"}
          type="submit"
          disabled={creating}
        >
          {creating ? buttonSpinner : "Registra"}
        </Button>
      </Col>
    </Form.Group>
  );

  let fieldEmail = (
    <>
      <Form.Check
        type="checkbox"
        id="z1"
        label="Il cliente desidera la ricevuta?"
        onChange={(e) => {
          handleChangeCheck();
        }}
      ></Form.Check>
      <Col xs={12}>
        <Row>
          <Form.Group as={Col} controlId="sendEmail">
            <FormElement
              label="Email per invio documento fiscale"
              key={"z5"}
              name="sendEmail"
              register={register}
              errors={errors}
              controlOptions={{ type: "email" }}
            ></FormElement>
          </Form.Group>
        </Row>
      </Col>
    </>
  );

  let msgError = null;
  let msgAlert = null;

  msgAlert = !!checkin?.customer?.address?.road
    ? msgAlert
    : "L'indirizzo del Cliente non è valido";
  msgAlert = !!checkin?.customer?.address?.city
    ? msgAlert
    : "L'indirizzo del Cliente non ha la città di residenza";
  msgAlert = !!checkin?.customer?.address?.county
    ? msgAlert
    : "L'indirizzo del Cliente non ha la provincia di residenza";
  msgAlert = !!checkin?.customer?.address?.postcode
    ? msgAlert
    : "L'indirizzo del Cliente non ha il CAP di residenza";
  msgAlert = !!checkin?.customer?.address?.road
    ? msgAlert
    : "Il Cliente non ha un indirizzo di residenza";
  msgAlert = !!checkin?.customer?.taxId
    ? msgAlert
    : "Il Cliente non ha il Codoce Fiscale";

  if (!!msgAlert) {
    msgError = (
      <Alert variant="danger">
        <p>{msgAlert}</p>
      </Alert>
    );
    btnCreate = null;
  }
  return (
    <>
      <Form
        onSubmit={(e) => {
          return handleSubmit(onSubmit)(e);
        }}
      >
        <Row>
          <Col xs={11}>
            <h4 className="text-center"> Emissione Ricevuta </h4>
          </Col>
          <Col xs={1}>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <ButtonGroup>
                {btnCreate}
                <DropdownButton
                  menualign="right"
                  title="Azioni"
                  id="dropdown-menu-align-right"
                  variant="outline-secondary"
                >
                  <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                  <Dropdown.Item href={getCustomerUrl(checkin?.customer?.uuid)}>
                    {addBsArrowRight("Cliente")}
                  </Dropdown.Item>
                  <Dropdown.Item href={getCheckinDetailUrl(checkin?.uuid)}>
                    {addBsArrowRight("CheckIn")}
                  </Dropdown.Item>
                </DropdownButton>
              </ButtonGroup>
            </div>
          </Col>
        </Row>
        <h5> Cliente</h5>
        <Row>
          <Col>
            <h6> Nome: </h6>
            <span>{checkin?.customer?.first} </span>
          </Col>
          <Col>
            <h6>Cognome</h6>
            <span>{checkin?.customer?.last}</span>
          </Col>
          <Col>
            <h6>Mail</h6>
            <span>{checkin?.customer?.email}</span>
          </Col>
          <Col>
            <h6>Codice Fiscale</h6>
            <span>{checkin?.customer?.taxId}</span>
          </Col>
        </Row>
        <hr />
        <h5> Dati Pagamento</h5>

        <div className="mt-4" />
        <Row>
          <Col>
            <h6> Costo Unitario: </h6>
            <span>{data?.productPrice}€ </span>
          </Col>
          <Col>
            <h6>N. Trattamenti</h6>
            <span>{checkin?.productZones}</span>
          </Col>
          <Col>
            <h6>Totale da pagare</h6>
            <span>{totalPrice}€</span>
          </Col>
          <Col>
            <h6> Trattamento</h6>
            <span>{checkin?.product?.name} </span>
          </Col>
        </Row>
        <hr />

        <Row>{showEmail ? fieldEmail : null}</Row>
        <div className="mt-4" />
        <Row></Row>
        <Row>
          <Form.Group as={Col} controlId="InternalNotes">
            <FormElement
              label="Note interne (non riportate in ricevuta)"
              key={"z6"}
              name="InternalNotes"
              register={register}
              errors={errors}
              controlOptions={{
                rows: "2",
                as: "textarea",
                placeholder: "",
              }}
            ></FormElement>
          </Form.Group>
        </Row>
        <Row>
          <ErrorMessage errors={[createError]} />
        </Row>
        <Row>{completed ? creatingSuccess : null}</Row>
        <Row>{msgError}</Row>
      </Form>
      <ContinueModal
        show={showModalContinue}
        onContinue={() => {
          setShowModalContinue(false);
          modalContinueClicked();
        }}
      />
    </>
  );
}

export default ReceiptCreateForm;
