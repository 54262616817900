import * as yup from "yup";

import React, { useState } from "react";
import { formatDateIso, isEmpty } from "../api/utils";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ErrorMessage } from "./ErrorMessage";
import Form from "react-bootstrap/Form";
import FormElement from "./FormElement";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import addBsArrowRight from "./AddBsArrowRight";
import dropDownHeaderView from "./DropDownHeaderView";
import { getCustomerUrl } from "../api/urls";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const VALIDATION_MANDATORY_FIELD = "Campo obbligatorio";
const schema = yup.object().shape({
  startDate: yup
    .date()
    .max(new Date(), "La data non può essere nel futuro")
    .required(VALIDATION_MANDATORY_FIELD),
  endDate: yup.date().required(VALIDATION_MANDATORY_FIELD),
  plan: yup.string().required(VALIDATION_MANDATORY_FIELD),
  zonesQuantity: yup
    .number()
    .required(VALIDATION_MANDATORY_FIELD)
    .positive("Il numero deve essere positivo")
    .typeError("Deve essere un numero"),
  sessionQuantity: yup
    .number()
    .required(VALIDATION_MANDATORY_FIELD)
    .positive("Il numero deve essere positivo")
    .typeError("Deve essere un numero"),
});
const DEFAULT_CREDIT_TYPE_UUID = "562912fa-d75c-4394-a916-3b88b1b4bc8c"; // Bonus

function load_Plan(plansList) {
  let listPlan = plansList
    .filter((plansList) => plansList?.disabled === false)
    .map((plansList) => {
      return {
        shortName: plansList?.shortName,
        name: plansList?.name,
        uuid: plansList?.uuid,
      };
    });

  return listPlan;
}
function load_CreditType(creditTypeList) {
  //Handles table elements disabled
  let listcreditType = creditTypeList
    .filter((creditTypeList) => creditTypeList?.enabled === true)
    .map((creditTypeList) => {
      return {
        shortName: creditTypeList?.shortName,
        name: creditTypeList?.name,
        uuid: creditTypeList?.uuid,
      };
    });

  return listcreditType;
}

function ContinueModal({ onContinue, show = false }) {
  return (
    <Modal show={show} onHide={onContinue}>
      <Modal.Header closeButton>
        <Modal.Title>Inserimento Credito</Modal.Title>
      </Modal.Header>
      <Modal.Body>Il Credito è stato inserito correttamente</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onContinue}>
          Continua
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function CreditInsertForm({
  data,
  createCredit,
  creating,
  completed,
  createError,
  modalContinueClicked = () => {},
}) {
  const [showModalContinue, setShowModalContinue] = useState(false);
  const customer = data?.customer;

  const today = new Date();
  const todayPlusOneYear = new Date(
    today.getFullYear() + 1,
    today.getMonth(),
    today.getDate()
  );
  const todayForControl = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1
  );
  const planList = load_Plan(data?.planList);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: formatDateIso(today),
      endDate: formatDateIso(todayPlusOneYear),
      plan: planList[0].uuid,
      creditType: DEFAULT_CREDIT_TYPE_UUID,
      sessionQuantity: 3,
      zonesQuantity: 1,
    },
  });

  const onSubmit = async (formData) => {
    const credit = {
      startDate: formatDateIso(formData.startDate),
      endDate: formatDateIso(formData.endDate),
      sessionQuantity: Number(formData.sessionQuantity),
      zonesQuantity: Number(formData.zonesQuantity),
      customer: customer.uuid,
      sponsor: formData.sponsor,
      notes: formData.notes,
      creditType: formData.creditType,
      plan: formData.plan,
    };
    const result = await createCredit({
      variables: {
        credit,
      },
    });
    if (!result?.errors) {
      setShowModalContinue(true);
    }
  };
  const creditTypeList = load_CreditType(data?.creditTypeList);

  const buttonSpinner = <Spinner animation="border" variant="light" />;
  const creatingSuccess = (
    <Alert variant="success"> Registrazione effettuata</Alert>
  );
  let btnUpdate = (
    <Form.Group as={Col} controlId="update">
      <Col>
        <Button
          variant={isEmpty(errors) ? "primary" : "danger"}
          type="submit"
          disabled={creating}
        >
          {creating ? buttonSpinner : "Registra"}
        </Button>
      </Col>
    </Form.Group>
  );
  return (
    <>
      <Form
        onSubmit={(e) => {
          return handleSubmit(onSubmit)(e);
        }}
      >
        <Row>
          <Col xs={11}>
            <h3 className="text-center"> Inserimento Credito </h3>
          </Col>
          <Col xs={1}>
            <ButtonGroup>
              {btnUpdate}
              <DropdownButton
                menualign="right"
                title="Azioni"
                id="dropdown-menu-align-right"
                variant="outline-secondary"
              >
                <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                <Dropdown.Item href={getCustomerUrl(customer?.uuid)}>
                  {addBsArrowRight("Cliente")}
                </Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          </Col>
        </Row>
        <div className="mt-4" />
        <Row>
          <Col>
            <h6>Nome </h6>
            <span>{customer?.first} </span>
          </Col>
          <Col>
            <h6>Cognome</h6>
            <span>{customer?.last}</span>
          </Col>
          <Col>
            <h6>Mail</h6>
            <span>{customer?.email}</span>
          </Col>
        </Row>

        <hr />
        <Row>
          <Form.Group as={Col} xs={3} controlId="startDate">
            <FormElement
              label="Data Inizio"
              type="date"
              name="startDate"
              register={register}
              errors={errors}
            ></FormElement>
          </Form.Group>

          <Form.Group as={Col} xs={3} controlId="endDate">
            <FormElement
              label="Data Scadenza"
              type="date"
              name="endDate"
              register={register}
              errors={errors}
              controlOptions={{ min: formatDateIso(todayForControl) }}
            ></FormElement>
          </Form.Group>

          <Form.Group as={Col} xs={4} controlId="plan">
            <FormElement
              label="Piano Pagamento"
              name="plan"
              register={register}
              errors={errors}
              controlOptions={{ as: "select" }}
            >
              {planList.map((w) => (
                <option key={w.uuid} value={w.uuid}>
                  {`${w.name}`}
                </option>
              ))}
            </FormElement>
          </Form.Group>
          <Form.Group as={Col} xs={2} controlId="creditType">
            <FormElement
              label="Tipo Credito"
              name="creditType"
              register={register}
              errors={errors}
              controlOptions={{ as: "select" }}
            >
              {creditTypeList.map((w) => (
                <option key={w.uuid} value={w.uuid}>
                  {`${w.name}`}
                </option>
              ))}
            </FormElement>
          </Form.Group>
        </Row>
        <div className="mt-4" />
        <Row>
          <Form.Group as={Col} xs={2} controlId="sessionQuantity">
            <FormElement
              label="Numero Sessioni"
              type="number"
              name="sessionQuantity"
              register={register}
              errors={errors}
              controlOptions={{
                defaultValue: "1",
                max: 150,
                min: 1,
              }}
            ></FormElement>
          </Form.Group>

          <Form.Group as={Col} xs={5} controlId="sponsor">
            <FormElement
              label="Suggerito da"
              name="sponsor"
              register={register}
              errors={errors}
            ></FormElement>
          </Form.Group>
          <Form.Group as={Col} xs={2} controlId="zonesQuantity">
            <FormElement
              label="Numero Zone"
              type="number"
              name="zonesQuantity"
              register={register}
              errors={errors}
              controlOptions={{ defaultValue: "1", max: 15, min: 0 }}
            ></FormElement>
          </Form.Group>
        </Row>
        <div className="mt-4" />
        <Row>
          <Form.Group as={Col} controlId="notes">
            <FormElement
              label="Note"
              name="notes"
              register={register}
              errors={errors}
              controlOptions={{ rows: "2", as: "textarea" }}
            ></FormElement>
          </Form.Group>
        </Row>
        <Row>
          <ErrorMessage errors={[createError]} />
        </Row>
        <Row>{completed ? creatingSuccess : null}</Row>
      </Form>
      <ContinueModal
        show={showModalContinue}
        onContinue={() => {
          setShowModalContinue(false);
          modalContinueClicked();
        }}
      />
    </>
  );
}

export default CreditInsertForm;
