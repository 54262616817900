import * as yup from "yup";

import React, { useState } from "react";
import { creditItalianState, formatDateIso, isEmpty } from "../api/utils";
import {
  getCreditCheckinsListUrl,
  getCreditDetailUrl,
  getCustomerUrl,
  getPlanUrl,
} from "../api/urls";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ErrorMessage } from "./ErrorMessage";
import Form from "react-bootstrap/Form";
import FormElement from "./FormElement";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import addBsArrowRight from "./AddBsArrowRight";
import constants from "../constants";
import dropDownHeaderView from "./DropDownHeaderView";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const VALIDATION_MANDATORY_FIELD = "Campo obbligatorio";
const schema = yup.object().shape({
  startDate: yup
    .date()
    .max(new Date(), "La data non può essere nel futuro")
    .required(VALIDATION_MANDATORY_FIELD),
  endDate: yup.date().required(VALIDATION_MANDATORY_FIELD),
  zonesQuantity: yup
    .number()
    .required(VALIDATION_MANDATORY_FIELD)
    .positive("Il numero deve essere positivo")
    .typeError("Deve essere un numero"),
  sessionQuantity: yup
    .number()
    .required(VALIDATION_MANDATORY_FIELD)
    .positive("Il numero deve essere positivo")
    .typeError("Deve essere un numero"),
});
const DEFAULT_PLAN_UUID = "4f8f890c-91a0-466f-bf59-f44ce61223cc"; // Laser 89
const DEFAULT_CREDIT_TYPE_UUID = "562912fa-d75c-4394-a916-3b88b1b4bc8c"; // Bonus

const creditState = {
  ACTIVE: constants.CREDIT_STATE_ACTIVE_KEY,
  CLOSED: constants.CREDIT_STATE_CLOSED_KEY,
  EXPIRED: constants.CREDIT_STATE_EXPIRED_KEY,
  CANCELED: constants.CREDIT_STATE_CANCELED_KEY,
};

function loadCreditListState(currentState) {
  let listState = [];
  listState.push({
    state: constants.CREDIT_STATE_ACTIVE_KEY,
    name: creditItalianState[constants.CREDIT_STATE_ACTIVE],
  });
  listState.push({
    state: constants.CREDIT_STATE_CANCELED_KEY,
    name: creditItalianState[constants.CREDIT_STATE_CANCELED],
  });
  if (currentState === constants.CREDIT_STATE_CLOSED)
    listState.push({
      state: constants.CREDIT_STATE_CLOSED_KEY,
      name: creditItalianState[constants.CREDIT_STATE_CLOSED],
    });
  if (currentState === constants.CREDIT_STATE_EXPIRED)
    listState.push({
      state: constants.CREDIT_STATE_EXPIRED_KEY,
      name: creditItalianState[constants.CREDIT_STATE_EXPIRED],
    });

  return listState;
}
function load_Plan(plansList, planData) {
  let listPlan = plansList
    .filter((plansList) => plansList?.disabled === false)
    .map((plansList) => {
      return {
        shortName: plansList?.shortName,
        name: plansList?.name,
        uuid: plansList?.uuid,
      };
    });

  if (planData?.disabled === true) {
    listPlan.push({
      uuid: planData?.uuid,
      name: planData?.name,
      shortName: planData?.shortName,
    });
  }

  return listPlan;
}
function load_CreditType(creditTypeList, creditTypeData) {
  //Handles table elements disabled
  let listcreditType = creditTypeList
    .filter((creditTypeList) => creditTypeList?.enabled === true)
    .map((creditTypeList) => {
      return {
        shortName: creditTypeList?.shortName,
        name: creditTypeList?.name,
        uuid: creditTypeList?.uuid,
      };
    });

  if (creditTypeData?.enabled === false) {
    listcreditType.push({
      uuid: creditTypeData?.uuid,
      name: creditTypeData?.name,
    });
  }
  return listcreditType;
}

function ConfirmDeleteModal({ onConfirm, onCancel, show = false }) {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Annulla Credito</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Questa azione elimina in modo definitivo i dati del credito
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Annulla operazione
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Annulla Credito
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ContinueModal({ onContinue, show = false }) {
  return (
    <Modal show={show} onHide={onContinue}>
      <Modal.Header closeButton>
        <Modal.Title>Modifica Credito</Modal.Title>
      </Modal.Header>
      <Modal.Body>Il Credito è stato aggiornato correttamente</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onContinue}>
          Continua
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function CreditUpdateForm({
  data,
  firstCheckin,
  lastCheckin,
  counterCheckIn,
  checkInNotPresentCounter,
  updateCredit,
  deleteCredit,
  creating,
  completed,
  createError,
  deleteCompleted = () => {},
  modalContinueClicked = () => {},
}) {
  const [showModal, setShowModal] = useState(false);
  const [showModalContinue, setShowModalContinue] = useState(false);
  const [msgValue, setMsgValue] = useState(null);

  const creditData = data?.credit;
  const creditUuid = creditData?.uuid;
  const stateCredit = creditData?.state;

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: creditData?.startDate,
      endDate: creditData?.endDate,
      plan: !!creditData?.plan?.uuid
        ? creditData?.plan?.uuid
        : DEFAULT_PLAN_UUID,
      creditType: !!creditData?.creditType?.uuid
        ? creditData?.creditType?.uuid
        : DEFAULT_CREDIT_TYPE_UUID,
      sessionQuantity: creditData?.sessionQuantity,
      notes: creditData?.notes,
      sponsor: creditData?.sponsor,
      state: creditState[creditData?.state],
      zonesQuantity: creditData?.zonesQuantity,
    },
  });

  const onDelete = async () => {
    const credit = {
      state: constants.CREDIT_STATE_CANCELED_KEY,
    };
    await deleteCredit({
      variables: {
        credit,
        guid: creditUuid,
      },
    })
      .then((result) => {
        if (!!result?.data?.updateCredit?.error) {
          // There is an error
          setMsgValue(
            <Alert variant="danger">
              {result?.data?.updateCredit?.error?.message}
            </Alert>
          );
        } else {
          deleteCompleted(result);
        }
      })
      .catch((error) => {
        let msg = `${error.message} ${error?.networkError?.result?.errors[0]?.message}`;
        setMsgValue(<Alert variant="danger">{msg}</Alert>);
      });
  };

  const onSubmit = async (formData) => {
    let newState = null;

    if (!!formData.state)
      newState =
        creditState[creditData?.state] !== Number(formData.state)
          ? Number(formData.state)
          : null;
    let newPlan = null;
    if (!!formData.plan)
      newPlan = creditData?.plan?.uuid !== formData.plan ? formData.plan : null;
    let newCreditType = null;
    if (!!formData.creditType)
      newCreditType =
        creditData?.creditType?.uuid !== formData.creditType
          ? formData.creditType
          : null;
    const credit = {
      startDate: formatDateIso(formData.startDate),
      endDate: formatDateIso(formData.endDate),
      sessionQuantity: Number(formData.sessionQuantity),
      state: newState,
      zonesQuantity: Number(formData.zonesQuantity),
      sponsor: formData.sponsor,
      notes: formData.notes,
      creditType: newCreditType,
      plan: newPlan,
    };
    const result = await updateCredit({
      variables: {
        credit,
        guid: creditUuid,
      },
    });
    if (!result?.errors) {
      setShowModalContinue(true);
    }
  };
  const listState = loadCreditListState(creditData?.state);
  const planList = load_Plan(data?.planList, creditData?.plan);
  const creditTypeList = load_CreditType(
    data?.creditTypeList,
    data?.credit?.creditType
  );

  const disabledPlan =
    !!creditData?.plan?.uuid === false || counterCheckIn > 0 ? true : false;

  const disabledCreditType =
    !!creditData?.creditType?.uuid === false || counterCheckIn > 0
      ? true
      : false;

  const sessionQuantity = `Sessioni (${
    counterCheckIn - checkInNotPresentCounter
  }/${creditData?.sessionQuantity})`;
  const IsStateOk = false;

  const validStartDate = !!firstCheckin ? firstCheckin : creditData?.startDate;
  const validEndDate = !!lastCheckin ? lastCheckin : creditData?.endDate;

  const buttonSpinner = <Spinner animation="border" variant="light" />;
  const creatingSuccess = (
    <Alert variant="success"> Registrazione effettuata</Alert>
  );

  const disableUpdate = true
    ? [
        constants.CREDIT_STATE_CANCELED,
        constants.CREDIT_STATE_CLOSED,
        constants.CREDIT_STATE_EXPIRED,
      ].includes(stateCredit)
    : false;

  let btnUpdate = (
    <Form.Group as={Col} controlId="update">
      <Col>
        <Button
          variant={isEmpty(errors) ? "primary" : "danger"}
          type="submit"
          disabled={creating || disableUpdate}
        >
          {creating ? buttonSpinner : "Aggiorna"}
        </Button>
      </Col>
    </Form.Group>
  );

  let btnDelete = (
    <Dropdown.Item
      onClick={() => {
        setShowModal(true);
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontSize: "130%",
          color: "red",
        }}
      >
        <span role="img" aria-label="danger">
          ⚠️
        </span>{" "}
        Annulla
      </div>
    </Dropdown.Item>
  );

  if (creditData?.state !== constants.CREDIT_STATE_ACTIVE) {
    btnDelete = <Col></Col>;
  }
  return (
    <>
      <Form
        onSubmit={(e) => {
          return handleSubmit(onSubmit)(e);
        }}
      >
        <Row>
          <Col xs={11}>
            <h3 className="text-center"> Modifica Credito </h3>
          </Col>
          <Col xs={1}>
            <ButtonGroup>
              {btnUpdate}
              <DropdownButton
                menualign="right"
                title="Azioni"
                id="dropdown-menu-align-right"
                variant="outline-secondary"
              >
                {btnDelete}
                <Dropdown.Divider />
                <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                <Dropdown.Item
                  href={getCustomerUrl(creditData?.customer?.uuid)}
                >
                  {addBsArrowRight("Cliente")}
                </Dropdown.Item>
                <Dropdown.Item href={getCreditDetailUrl(creditUuid)}>
                  {addBsArrowRight("Credito")}
                </Dropdown.Item>
                <Dropdown.Item href={getCreditCheckinsListUrl(creditUuid)}>
                  {addBsArrowRight("Elenco Check-In Credito")}
                </Dropdown.Item>
                <Dropdown.Item href={getPlanUrl(creditData?.plan?.uuid)}>
                  {addBsArrowRight("Piano")}
                </Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          </Col>
        </Row>
        <div className="mt-4" />
        <Row>
          <Col>
            <h6>Nome </h6>
            <span>{creditData?.customer?.first} </span>
          </Col>
          <Col>
            <h6>Cognome</h6>
            <span>{creditData?.customer?.last}</span>
          </Col>
          <Col>
            <h6>Mail</h6>
            <span>{creditData?.customer?.email}</span>
          </Col>
        </Row>

        <hr />
        <Row>
          <Form.Group as={Col} xs={3} controlId="startDate">
            <FormElement
              label="Data Inizio"
              type="date"
              name="startDate"
              register={register}
              errors={errors}
              controlOptions={{ min: validStartDate }}
            ></FormElement>
          </Form.Group>

          <Form.Group as={Col} xs={3} controlId="endDate">
            <FormElement
              label="Data Scadenza"
              type="date"
              name="endDate"
              register={register}
              errors={errors}
              controlOptions={{ min: validEndDate }}
            ></FormElement>
          </Form.Group>

          <Form.Group as={Col} xs={4} controlId="plan">
            <FormElement
              label="Piano"
              name="plan"
              register={register}
              errors={errors}
              controlOptions={{ as: "select", disabled: disabledPlan }}
            >
              {planList.map((w) => (
                <option key={w.uuid} value={w.uuid}>
                  {`${w.name}`}
                </option>
              ))}
            </FormElement>
          </Form.Group>
          <Form.Group as={Col} xs={2} controlId="creditType">
            <FormElement
              label="Tipo Credito"
              name="creditType"
              register={register}
              errors={errors}
              controlOptions={{ as: "select", disabled: disabledCreditType }}
            >
              {creditTypeList.map((w) => (
                <option key={w.uuid} value={w.uuid}>
                  {`${w.name}`}
                </option>
              ))}
            </FormElement>
          </Form.Group>
        </Row>
        <div className="mt-4" />
        <Row>
          <Form.Group as={Col} xs={3} controlId="state">
            <FormElement
              label="Stato"
              name="state"
              register={register}
              errors={errors}
              controlOptions={{ as: "select", disabled: !IsStateOk }}
            >
              {listState.map((w) => (
                <option key={w.state} value={w.state}>
                  {`${w.name}`}
                </option>
              ))}
            </FormElement>
          </Form.Group>
          <Form.Group as={Col} xs={2} controlId="sessionQuantity">
            <FormElement
              label={sessionQuantity}
              type="number"
              name="sessionQuantity"
              register={register}
              errors={errors}
              controlOptions={{
                defaultValue: "1",
                max: 150,
                min: counterCheckIn,
              }}
            ></FormElement>
          </Form.Group>

          <Form.Group as={Col} xs={5} controlId="sponsor">
            <FormElement
              label="Suggerito da"
              name="sponsor"
              register={register}
              errors={errors}
            ></FormElement>
          </Form.Group>
          <Form.Group as={Col} xs={2} controlId="zonesQuantity">
            <FormElement
              label="Numero Zone"
              type="number"
              name="zonesQuantity"
              register={register}
              errors={errors}
              controlOptions={{ defaultValue: "1", max: 15, min: 0 }}
            ></FormElement>
          </Form.Group>
        </Row>
        <div className="mt-4" />
        <Row>
          <Form.Group as={Col} controlId="notes">
            <FormElement
              label="Note"
              name="notes"
              register={register}
              errors={errors}
              controlOptions={{ rows: "2", as: "textarea" }}
            ></FormElement>
          </Form.Group>
        </Row>
        <Row>
          <ErrorMessage errors={[createError]} />
        </Row>
        <Row>{completed ? creatingSuccess : null}</Row>
        <Row>{msgValue !== "" ? msgValue : null}</Row>
      </Form>
      <ContinueModal
        show={showModalContinue}
        onContinue={() => {
          setShowModalContinue(false);
          modalContinueClicked();
        }}
      />
      <ConfirmDeleteModal
        show={showModal}
        onConfirm={onDelete}
        onCancel={() => {
          setShowModal(false);
        }}
      />
    </>
  );
}

export default CreditUpdateForm;
