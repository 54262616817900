import { creditItalianState, diffDays } from "../api/utils";
import {
  getCreditDetailUrl,
  getCustomerCheckinsListUrl,
  getCustomerNewCheckInUrl,
  getCustomerUrl,
  getPlanUrl,
} from "../api/urls";

import ActionsButton from "../components/ActionsButton";
import CreditList from "../components/CreditList";
import { ErrorMessage } from "../components/ErrorMessage";
import { QUERY_LIST_CREDIT_PAGINATOR } from "../api/queries";
import React from "react";
import constants from "../constants";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";

function loadAllCredit(creditList, page, pageSize) {
  let j = (page - 1) * pageSize;

  let arrayListCredit = creditList.map((s, index) => {
    let l = s?.credit;

    const lastCheckinDate = s?.lastCheckin;
    const passedDays = !!lastCheckinDate
      ? diffDays(new Date(lastCheckinDate), new Date())
      : "";

    let lastCheckin = !!lastCheckinDate
      ? `${lastCheckinDate} (${passedDays} gg)`
      : "";

    // Check if CheckIn is today
    lastCheckin =
      passedDays === 0 && !!lastCheckinDate ? lastCheckinDate : lastCheckin;

    return {
      startDate: l?.startDate,
      planName: l?.plan.name,
      checkinCount: s?.checkinCount,
      customerFullName: l?.customer?.fullName,
      customerUuid: l?.customer?.uuid,
      planUuid: l?.plan?.uuid,
      needsUpdate: l?.customer?.needsUpdate,
      stateIt: creditItalianState[l?.state],
      sessionQuantity: l.sessionQuantity,
      creditUuid: l?.uuid,
      creditType: l.creditType.name,
      creditTypeUuid: l.creditType.uuid,
      state: l?.state,
      lastCheckIn: lastCheckin,
      index: j + index + 1,
    };
  });

  return arrayListCredit;
}

function loadCreditType(creditTypeList) {
  let listCreditType = [{ key: "0", name: "Tutti" }];
  let arrayListCreditType = creditTypeList.map((s, index) => {
    return {
      key: index + 1,
      name: s.name,
    };
  });
  // Merge the second array into the first one
  listCreditType.push(...arrayListCreditType);
  return listCreditType;
}

function CreditListPage() {
  const defaultOrder = "-start_date"; //if "" is set, the order is established by resolver
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const page = parseInt(urlParams.get("page"));
  let numPage = !!page ? page : 1;

  let filter =
    urlParams.get("filter") === null
      ? `state:${constants.CREDIT_STATE_ACTIVE}`
      : urlParams.get("filter");

  let order =
    urlParams.get("order") === null ? defaultOrder : urlParams.get("order");
  const paramVar = order === "" ? filter : `${filter}:${order}`;

  const PAGESIZE = 50; //set row for Page

  const { loading, error, data } = useQuery(QUERY_LIST_CREDIT_PAGINATOR, {
    variables: {
      page: numPage,
      pageSize: PAGESIZE,
      parameter: paramVar,
    },
  });

  const popoverLinksList = (cell) => {
    let linksList = [];

    const enabled = cell?.state === constants.CREDIT_STATE_ACTIVE;
    const showDisplayNewCheckin =
      !cell?.needUpdate && enabled && !!cell?.customerUuid;
    const showListCheckIn = cell?.checkinCount === 0;

    if (showDisplayNewCheckin) {
      linksList.push({
        title: "Nuovo Check-In",
        url: getCustomerNewCheckInUrl(cell?.customerUuid),
      });
    }
    if (showListCheckIn) {
      linksList.push({
        title: "Visualizza Elenco Check-In",
        url: getCustomerCheckinsListUrl(cell?.customerUuid),
      });
    }

    linksList = [
      ...linksList,
      {
        title: "Visualizza Credito",
        url: getCreditDetailUrl(cell?.creditUuid),
      },
      {
        title: "Visualizza Cliente",
        url: getCustomerUrl(cell?.customerUuid),
      },
      {
        title: "Visualizza Piano",
        url: getPlanUrl(cell?.planUuid),
      },
    ];

    return linksList;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            N.
          </div>
        ),
        accessor: "index",
        width: 20,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Cliente",
        accessor: "customerFullName",
        width: 90,
      },
      {
        Header: "Data Inizio",
        accessor: "startDate",
        width: 55,
      },
      {
        Header: "Piano",
        accessor: "planName",
        width: 120,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Numero Check-In
          </div>
        ),
        accessor: "checkinCount",
        width: 45,
        Cell: ({ cell }) => {
          let checkInCount = `${cell?.row?.original.checkinCount}/${cell?.row?.original.sessionQuantity} `;
          return <div style={{ textAlign: "center" }}>{checkInCount}</div>;
        },
      },
      {
        Header: "Ultimo CheckIn",
        accessor: "lastCheckIn",
        width: 90,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Stato
          </div>
        ),
        accessor: "stateIt",
        width: 40,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "",
        accessor: "menu",
        width: 25,
        Cell: ({ cell }) => {
          const links = popoverLinksList(cell?.row?.original);
          return <ActionsButton linksList={links} />;
        },
      },
    ],
    []
  );

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }
  //

  const creditList = data?.creditPaginator?.listCredit;
  const listCredit = loadAllCredit(creditList, numPage, PAGESIZE);
  const creditTypeList = data?.creditTypeList;
  const listCreditType = loadCreditType(creditTypeList);

  return (
    <CreditList
      data={listCredit}
      columns={columns}
      listCreditType={listCreditType}
      paginator={data?.paginator}
      filter={filter}
      order={order}
    />
  );
}
export default CreditListPage;
