import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";
import {
  useTable,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
} from "react-table";
import constants from "../constants";
import dropDownHeaderView from "../components/DropDownHeaderView";
import addBsArrowRight from "../components/AddBsArrowRight";
import {
  getSubscriptionDetailUrl,
  getCustomerUrl,
  getPlanUrl,
  getSubscriptionInvoicesListUrl,
  getCustomerNewCheckInUrl,
} from "../api/urls";

function SubscriptionCheckInsList({ columns, data, subscription }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy,
      useFlexLayout,
      useResizeColumns
    );

  const customer = subscription?.customer;
  const listPayment =
    subscription?.paymentType.toUpperCase() ===
    constants.STRIPE_PAYMENT_METHOD ? (
      <Dropdown.Item href={getSubscriptionInvoicesListUrl(subscription?.uuid)}>
        {addBsArrowRight("Elenco Pagamenti")}
      </Dropdown.Item>
    ) : null;

  const enabled =
    subscription?.state === constants.SUBSCRIPTION_STATE_ACTIVE ||
    subscription?.state === constants.SUBSCRIPTION_STATE_PAUSED;
  const newCheckIn =
    !customer?.needsUpdate && enabled && customer?.uuid ? (
      <Dropdown.Item href={getCustomerNewCheckInUrl(customer?.uuid)}>
        Nuovo Check-In
      </Dropdown.Item>
    ) : null;

  return (
    <>
      <Row>
        <Col xs={8}>
          <h3 className="text-center"> Elenco Check-In Abbonamento </h3>
        </Col>
        <Col xs={4}>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <ButtonGroup>
              <DropdownButton
                menualign="right"
                title="Azioni"
                id="dropdown-menu-align-right"
                variant="outline-primary"
              >
                {newCheckIn}
                <Dropdown.Divider />
                <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                <Dropdown.Item
                  href={getSubscriptionDetailUrl(subscription?.uuid)}
                >
                  {addBsArrowRight("Abbonamento")}
                </Dropdown.Item>
                <Dropdown.Item href={getCustomerUrl(customer?.uuid)}>
                  {addBsArrowRight("Cliente")}
                </Dropdown.Item>
                <Dropdown.Item href={getPlanUrl(subscription?.plan?.uuid)}>
                  {addBsArrowRight("Piano")}
                </Dropdown.Item>
                {listPayment}
              </DropdownButton>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6> Data Inizio </h6>
          <span>{subscription?.startDate} </span>
        </Col>
        <Col>
          <h6>Piano</h6>
          <span>{subscription?.plan?.name}</span>
        </Col>
        <Col>
          <h6>Metodo di pagamento </h6>
          <span>{subscription?.paymentType}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6> Numero Zone </h6>
          <span>{subscription?.zonesQuantity} </span>
        </Col>
        <Col>
          <h6> Stato </h6>
          <span>{subscription?.state} </span>
        </Col>
        <Col>
          <h6>Sede Corrente</h6>
          <span>{subscription?.location?.name}</span>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h6>Nome: </h6>
          <span>{customer.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{customer.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{customer.email}</span>
        </Col>
      </Row>

      <div className="mt-4" />

      <Table striped bordered hover size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumnProps } = column.getHeaderProps(
                    column.getSortByToggleProps()
                  );
                  return (
                    <th key={key} {...restColumnProps}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? constants.DESCENDING_SORT_SIMBOL
                            : constants.ASCENDING_SORT_SIMBOL
                          : ""}
                      </span>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <tr key={key} {...restRowProps}>
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  return (
                    <td key={key} {...restCellProps}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default SubscriptionCheckInsList;
