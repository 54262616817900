import {
  getCheckinDetailUrl,
  getCreditCheckinsListUrl,
  getCreditUpdateUrl,
  getCustomerUrl,
  getPlanUrl,
} from "../api/urls";

import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ListGroup from "react-bootstrap/ListGroup";
import NotesDisplay from "./NotesDisplay";
import React from "react";
import Row from "react-bootstrap/Row";
import addBsArrowRight from "../components/AddBsArrowRight";
import { creditItalianState } from "../api/utils";
import dropDownHeaderView from "../components/DropDownHeaderView";

function CreditDetailForm({
  creditdata,
  checkinList,
  counterCheckIn,
  checkInNotPresentCounter,
  currentLocation,
}) {
  const creditUuid = creditdata?.uuid;
  const isSameLocation =
    currentLocation?.uuid === creditdata?.location?.uuid ? true : false;
  //
  // load Button for list all Check-Ins
  //
  let btnPlusCheckIn = null;
  if (checkinList?.length !== 0) {
    btnPlusCheckIn = (
      <Button
        variant="outline-info"
        size="sm"
        as="a"
        className="pr-2"
        key={"z3"}
        action
        href={getCreditCheckinsListUrl(creditUuid)}
      >
        Lista completa Check-In <Badge variant="info">{counterCheckIn}</Badge>
      </Button>
    );
  }
  //
  //load list check-ins
  //
  let checkinListGroupItems = null;
  if (checkinList?.length === 0) {
    checkinListGroupItems = (
      <ListGroup.Item disabled className="list-group-item-ck">
        Non ci sono check-in effettuati
      </ListGroup.Item>
    );
  } else {
    checkinList = checkinList.slice(0, 3);
    checkinListGroupItems = (
      <>
        {checkinList.map((s) => {
          return (
            <ListGroup.Item
              key={s.uuid}
              value={s.index}
              className="list-group-item-ck"
              action
              href={getCheckinDetailUrl(s.uuid)}
            >
              {s.checkInStatus}
              {s.checkInDate.substr(2)} | {s.checkInPlanShortName} |{" "}
              {s.checkInProductName}
            </ListGroup.Item>
          );
        })}
      </>
    );
  }
  const sessionNumber = counterCheckIn - checkInNotPresentCounter;
  return (
    <>
      <Row>
        <Col xs={11}>
          <h3 className="text-center"> Credito </h3>
        </Col>
        <Col xs={1}>
          <ButtonGroup>
            <DropdownButton
              menualign="right"
              title="Azioni"
              id="dropdown-menu-align-right"
              variant="outline-primary"
            >
              <Dropdown.Item
                href={getCreditUpdateUrl(creditdata?.uuid)}
                disabled={!isSameLocation}
              >
                Modifica Credito
              </Dropdown.Item>

              <Dropdown.Divider />
              <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
              <Dropdown.Item href={getCustomerUrl(creditdata?.customer?.uuid)}>
                {addBsArrowRight("Cliente")}
              </Dropdown.Item>
              <Dropdown.Item href={getCreditCheckinsListUrl(creditdata?.uuid)}>
                {addBsArrowRight("Elenco Check-In Credito")}
              </Dropdown.Item>
              <Dropdown.Item href={getPlanUrl(creditdata?.plan?.uuid)}>
                {addBsArrowRight("Piano")}
              </Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Nome </h6>
          <span>{creditdata?.customer?.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{creditdata?.customer?.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{creditdata?.customer?.email}</span>
        </Col>
      </Row>

      <hr />
      <Row>
        <Col xs={4}>
          <h6>Data Inizio </h6>
          <span>{creditdata?.startDate} </span>
        </Col>
        <Col xs={4}>
          <h6>Data Scadenza </h6>
          <span>{creditdata?.endDate} </span>
        </Col>
        <Col xs={4}>
          <h6>Piano</h6>
          <span>{creditdata?.plan?.name}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col xs={4}>
          <h6>Tipo </h6>
          <span>{creditdata?.creditType?.name}</span>
        </Col>
        <Col xs={4}>
          <h6> Sessioni</h6>
          <span>
            {sessionNumber}/{creditdata?.sessionQuantity}{" "}
          </span>
        </Col>
        <Col xs={4}>
          <h6>Stato</h6>
          <span>{creditItalianState[creditdata?.state]}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col xs={4}>
          <h6>Suggerito da </h6>
          <span>{creditdata?.sponsor}</span>
        </Col>
        <Col xs={4}>
          <h6>Numero Zone </h6>
          <span>{creditdata?.zonesQuantity}</span>
        </Col>
        <Col xs={4}>
          <h6>Sede Corrente</h6>
          <span>{creditdata?.location?.name}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>CheckIn effettuati in presenza</h6>
          <span> {sessionNumber}</span>
        </Col>
        <Col>
          <h6>CheckIn Non Presente</h6>
          <span> {checkInNotPresentCounter}</span>
        </Col>
        <Col></Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col>
          <h6>Note</h6>
          <NotesDisplay notes={creditdata?.notes} />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Row>
            <Col>
              <h6>Ultimi Check-In effettuati</h6>
            </Col>
            <Col>
              <div
                style={{
                  textAlign: "right",
                }}
              >
                {btnPlusCheckIn}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListGroup className="list-group-ck">
                {checkinListGroupItems}
              </ListGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default CreditDetailForm;
