import "./App.css";

import {
  getSubscriptionCheckinsListUrl,
  getSubscriptionInvoicesListUrl,
} from "../api/urls";
import { loadListCheckIn, loadListInvoice } from "../api/utils";

import { ErrorMessage } from "../components/ErrorMessage";
import { QUERY_GET_SUBSCRIPTION } from "../api/queries";
import { QUERY_GET_SUBSCRIPTION_PAYMENT } from "../api/queries";
import React from "react";
import SubscriptionDetailForm from "../components/SubscriptionDetailForm";
import constants from "../constants";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

function SubscriptionDetailPage() {
  const navigate = useNavigate();

  let { guid } = useParams();
  const subscriptionUuid = guid;
  const LIMIT_MAX_RECORD = 3;

  const { loading, error, data } = useQuery(QUERY_GET_SUBSCRIPTION, {
    variables: {
      subscriptionUuid: guid,
      checkInMaxLimit: LIMIT_MAX_RECORD,
    },
  });
  const { loading: payLoading, data: payData } = useQuery(
    QUERY_GET_SUBSCRIPTION_PAYMENT,
    {
      variables: {
        subscriptionUuid: guid,
      },
    }
  );

  if (loading || payLoading) {
    return <p>Loading..</p>;
  }

  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  let subscriptionData = data?.subscription;
  const userSetting = data?.userSetting;

  const counterCheckIn = data?.checkInCounter;
  const counterCheckInNotPresent = data?.checkInCounterNotPresent;
  const invoiceCounterExpired = data?.invoiceCounterExpired;
  const checkinList = loadListCheckIn(data?.subscriptionCheckins);

  let invoiceList = null;

  const dataAvailable =
    subscriptionData?.paymentType === constants.STRIPE_PAYMENT_METHOD &&
    !!payData &&
    !!payData?.invoiceForSubscription?.result &&
    !!payData?.stripeSubscription?.result;
  if (dataAvailable) {
    invoiceList = loadListInvoice(payData?.invoiceForSubscription?.result);
  }

  function openSubscriptionCheckinsList(values) {
    navigate(getSubscriptionCheckinsListUrl(subscriptionUuid));
  }

  function openSubscriptionInvoicesList(values) {
    navigate(getSubscriptionInvoicesListUrl(subscriptionUuid));
  }

  return (
    <>
      <SubscriptionDetailForm
        data={subscriptionData}
        payData={payData}
        checkinList={checkinList}
        counterCheckIn={counterCheckIn}
        counterCheckInNotPresent={counterCheckInNotPresent}
        invoiceCounterExpired={invoiceCounterExpired}
        openSubscriptionCheckinsList={openSubscriptionCheckinsList}
        invoiceForSubscription={invoiceList}
        openSubscriptionInvoicesList={openSubscriptionInvoicesList}
        userSetting={userSetting}
      />
    </>
  );
}

export default SubscriptionDetailPage;
