import ActionsButton from "../components/ActionsButton";
import { ErrorMessage } from "../components/ErrorMessage";
import { QUERY_GET_SUBSCRIPTION_CHECKINS } from "../api/queries";
import React from "react";
import SubscriptionCheckInsList from "../components/SubscriptionCheckInsList";
import { formatLocalTime, calculateDurationCheckIn } from "../api/utils";
import { getCheckinDetailUrl } from "../api/urls";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import constants from "../constants";

function LoadCheckInList(customerCheckin) {
  let listCheckIn = customerCheckin.map((s, index) => {
    const present = s.isPresent ? s?.product?.name : "Non Presentato";
    const checkinStatus =
      s.checkInStatus === constants.CHECK_IN_STATUS_TYPE_PENDING
        ? constants.STATUS_PENDING
        : null;
    const checkInMinute = calculateDurationCheckIn(
      s?.checkInStatus,
      s?.closedDate,
      s?.timestamp
    );
    const checkInDuration = !!checkInMinute ? checkInMinute : null;
    return {
      uuid: s?.uuid,
      date: s.date,
      product: present,
      worker: s?.worker?.last,
      time: formatLocalTime(s?.time).substr(0, 5),
      paymentType: s?.subscription?.paymentType,
      state: s?.subscription?.state,
      needsUpdate: s?.subscription?.customer?.needsUpdate,
      notes: s?.notes,
      checkInStatus: checkinStatus,
      checkInDuration: checkInDuration,
      index: index + 1,
    };
  });
  return listCheckIn;
}

function SubscriptionCheckInsListPage() {
  const { subscriptionUuid } = useParams();

  const LIMIT_MAX_RECORD = 100;

  const { loading, error, data } = useQuery(QUERY_GET_SUBSCRIPTION_CHECKINS, {
    variables: {
      subscriptionUuid: subscriptionUuid,
      checkInMaxLimit: LIMIT_MAX_RECORD,
    },
  });

  const popoverLinksList = (cell) => {
    let linksList = [];

    linksList = [
      ...linksList,
      {
        title: "Visualizza Check-In",
        url: getCheckinDetailUrl(cell.uuid),
      },
    ];
    return linksList;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            N.
          </div>
        ),
        accessor: "index",
        width: 10,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Data",
        accessor: "date",
        width: 30,
        Cell: (cell) => (
          <div style={{ textAlign: "center" }}>
            {cell?.row?.original.checkInStatus}
            {cell?.row?.original.date}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Ora
          </div>
        ),
        accessor: "time",
        width: 20,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Durata (minuti)
          </div>
        ),
        accessor: "checkInDuration",
        width: 12,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Trattamento",
        accessor: "product",
        width: 50,
      },
      {
        Header: "Estetista",
        accessor: "worker",
        width: 30,
      },
      {
        Header: "Note",
        accessor: "notes",
        width: 60,
      },
      {
        Header: "",
        accessor: "menu",
        width: 15,
        Cell: ({ cell }) => {
          const links = popoverLinksList(cell?.row?.original);
          return <ActionsButton linksList={links} />;
        },
      },
    ],
    []
  );

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }

  let checkInListData = data?.subscriptionCheckins
    ? data?.subscriptionCheckins
    : [];

  const checkinList = LoadCheckInList(checkInListData);

  return (
    <>
      <SubscriptionCheckInsList
        columns={columns}
        data={checkinList}
        subscription={data?.subscription}
      />
    </>
  );
}
export default SubscriptionCheckInsListPage;
